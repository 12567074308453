<template>
  <div class="public-box">
    <el-form>
      <el-form-item>
        <MyButton @click="add" :accessId="6552">
          <span>新增合同模板</span>
          <template slot="preImage">
            <img src="../../../unit/img/zj.png" alt="" />
          </template>
        </MyButton>
      </el-form-item>
    </el-form>

    <!-- table -->
    <div class="public-table">
      <el-table :data="tableData" v-loading="loading" height="95%" :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column label="合同模板名称" prop="values.name"></el-table-column>
        <el-table-column label="收费项" show-overflow-tooltip prop="values.chargingItemsName">
          <template slot-scope="scope">
            {{ scope.row.values.chargingItemsName.join(",") }}
          </template>
        </el-table-column>
        <el-table-column label="合同编号规则" prop="values.child_value">
          <template slot-scope="scope">
            <div>
              <!-- <div>{{getRule(scope.row)}}</div> -->
              <div>
                <!-- <pop tips="编辑编号规则" :accessId="98508" @myclick="ruleNo(scope.row, 'rule')" popRight>
                  <img src="../../../assets/img/icon/bianhao.png" class="icon" alt="">
                </pop> -->
                <myButton style="border: none; box-shadow: none" type="primary" :accessId="98508"
                  @click="ruleNo(scope.row, 'rule')">编辑编号规则</myButton>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="电子合同章">
          <template slot-scope="scope">
            <div>
              <!-- <div>
             <img :src="scope.row.child_value.values.electronicSeal" v-preview style="width:100px;" alt="" v-if="scope.row.child_value.values">
            </div> -->
              <div>
                <!-- <pop tips="电子章上传" :accessId="95351" @myclick="ruleNo(scope.row, 'file')">
                  <img src="../../../assets/img/icon/dianzizhang.png" class="icon" alt="">
                </pop> -->
                <myButton style="border: none; box-shadow: none" type="primary" :accessId="95351"
                  @click="ruleNo(scope.row, 'file')">电子章上传</myButton>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="create_at"></el-table-column>
        <el-table-column label="创建人" prop="worker_name"></el-table-column>
        <el-table-column label="备注" prop="values.remark"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- <pop tips="编辑"  popRight>
              <img src="../../../assets/img/icon/edit.png" class="icon" alt="">
            </pop> -->
            <myButton style="border: none; box-shadow: none" type="primary" :accessId="6552" @click="edit(scope.row)">编辑
            </myButton>
            <!-- <pop tips="编辑编号规则" :accessId="98508"  @myclick="ruleNo(scope.row, 'rule')" popRight>
              <img src="../../../assets/img/icon/bianhao.png" class="icon" alt="">
            </pop> -->
            <myButton style="border: none; box-shadow: none" type="primary" @click="getPre(scope.row)">预览
            </myButton>
            <!-- <pop tips="预览"   @myclick="ruleNo(scope.row, 'file')">
              <img src="../../../assets/img/icon/xianshi.png" class="icon" alt="">
            </pop> -->
          </template>
        </el-table-column>
      </el-table>
      <div style="color: red; margin-top: 5px">
        注：对合同模板修改在操作项内进行编辑
      </div>
    </div>
    <div class="public-page">
      <el-pagination :total="total" next-text="下一页" prev-text="上一页" :current-page="formInline.pageNum"
        :page-size="formInline.pageSize" @current-change="currentChange" layout="total,prev,pager,next"></el-pagination>
    </div>
    <!-- 新增 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="addvisible" :title="title" width="880px" @close="close">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="合同模板名称:" prop="values.name">
          <el-input class="public-input" v-model="ruleForm.values.name" placeholder="请输入合同模板名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="甲方市场名称:" prop="values.marketName">
          <el-input class="public-input" v-model="ruleForm.values.marketName" placeholder="请输入甲方市场名称" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="联系地址:" prop="values.address">
          <el-input class="public-input" v-model="ruleForm.values.address" placeholder="请输入联系地址" clearable></el-input>
        </el-form-item>
        <el-form-item label="联系人电话:" prop="values.mobile">
          <el-input class="public-input" v-model="ruleForm.values.mobile" placeholder="请输入联系人电话" clearable></el-input>
        </el-form-item>
        <el-form-item label="联系邮箱:">
          <el-input class="public-input" v-model="ruleForm.values.email" placeholder="请输入联系邮箱" clearable></el-input>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input class="public-input" v-model="ruleForm.values.remark" placeholder="请输入备注" clearable></el-input>
        </el-form-item>
        <el-form-item label="收费项:" prop="values.chargingItemsId">
          <el-select v-model="ruleForm.values.chargingItemsId" multiple clearable class="public-input"
            placeholder="请选择收费项">
            <el-option :label="item.contract_fee_name" :value="item.id" v-for="item in contractFeeSettingList"
              :key="item.id"></el-option>
          </el-select>
          <MyButton left title="新增收费项" :accessId="77250" @click="feesdialogVisible = true">
            <template slot="preImage">
              <img src="@/unit/img/zj.png" alt="" />
            </template>
          </MyButton>
        </el-form-item>
        <!-- <el-form-item>
          <div class="public-tips">
            提示：收费项在收费管理-收费设置-合同收费设置中进行添加
            <span @click="$router.push('/charge/chargesetting/contractchargeset')">点击前往</span>
          </div>
        </el-form-item> -->
      </el-form>
      <template slot="footer">
        <MyButton @click="addvisible = false" right>取消</MyButton>
        <MyButton @click="nextStep" type="primary">下一步</MyButton>
      </template>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible" title="内容编辑" width="880px" @close="contentClose"
      :close-on-click-modal="false">
      <el-form ref="contentRuleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="基础信息:">
          <MyButton right class="marginBt" @click="insertion(item)" v-for="(item, index) in ruleForm.values.chargingItems.filter(
            (item) => item.type == 1
          )" :key="index">{{ item.name }}</MyButton>
        </el-form-item>
        <el-form-item label="费用信息:">
          <MyButton right class="marginBt" @click="insertion(item)" v-for="(item, index) in ruleForm.values.chargingItems.filter(
            (item) => item.type == 2
          )" :key="index">{{ item.name }}</MyButton>
        </el-form-item>
        <el-form-item label="地址信息:">
          <MyButton right class="marginBt" @click="insertion(item)" v-for="(item, index) in ruleForm.values.chargingItems.filter(
            (item) => item.type == 3
          )" :key="index">{{ item.name }}</MyButton>
        </el-form-item>
        <div id="contractTemplate" style="border: 1px solid #ccc" v-if="dialogVisible">
          <!-- <quill-editor v-model="ruleForm.values.content" ref="myQuillEditor" :options="editorOption" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @change="onEditorChange($event)"></quill-editor> -->
          <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
          <Editor style="height: 500px; overflow-y: auto" v-model="ruleForm.values.content" :defaultConfig="editorConfig"
            :mode="mode" @onCreated="onCreated" ref="myQuillEditor" @onChange="onEditorChange($event)" />
        </div>
      </el-form>
      <template slot="footer">
        <MyButton @click="dialogVisible = false" right>取消</MyButton>
        <MyButton @click="confirm" type="primary">确定</MyButton>
      </template>
    </el-dialog>

    <el-dialog :visible.sync="contractNoVisible" title="合同编号编译规则设置" width="880px" @close="contractNoClose"
      :close-on-click-modal="false">
      <el-form :model="contractNoRuleForm" ref="contractNoRuleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="合同编号组成:">
          <div class="listBox">
            <div class="listHead">
              <div class="list"></div>
              <div class="list"></div>
              <div class="list"></div>
              <div class="list"></div>
              <div class="segmentation">-</div>
              <div class="list">2</div>
              <div class="list">0</div>
              <div class="list">2</div>
              <div class="list">2</div>
              <div class="list">0</div>
              <div class="list">2</div>
              <div class="list">2</div>
              <div class="list">3</div>
              <div class="list"></div>
              <div class="list"></div>
              <div class="list"></div>
              <div class="list"></div>
              <div class="list"></div>
              <div class="list"></div>
            </div>
            <div class="listName">
              <div class="list">1</div>
              <div class="list">2</div>
              <div class="list">3</div>
              <div class="list">4</div>
              <div class="segmentation">-</div>
              <div class="list">5</div>
              <div class="list">6</div>
              <div class="list">7</div>
              <div class="list">8</div>
              <div class="list">9</div>
              <div class="list">10</div>
              <div class="list">11</div>
              <div class="list">12</div>
              <div class="list">13</div>
              <div class="list">14</div>
              <div class="list">15</div>
              <div class="list">16</div>
              <div class="list">17</div>
              <div class="list">18</div>
            </div>
          </div>
        </el-form-item>
        <el-divider></el-divider>
        <div class="contractNoBox">
          <div class="left">
            <el-form-item label="1号字母设置:">
              <el-input placeholder="请输入1号字母" :maxlength="1" v-model="contractNoRuleForm.values.contractNumber1"
                class="public-input"></el-input>
            </el-form-item>
            <el-form-item label="3号字母设置:">
              <el-input placeholder="请输入3号字母" :maxlength="1" v-model="contractNoRuleForm.values.contractNumber3"
                class="public-input"></el-input>
            </el-form-item>
            <el-form-item label="16号字母设置:">
              <el-input placeholder="请输入16号字母" :maxlength="1" v-model="contractNoRuleForm.values.contractNumber16"
                class="public-input"></el-input>
            </el-form-item>
            <el-form-item label="18号字母设置:">
              <el-input placeholder="请输入18号字母" :maxlength="1" v-model="contractNoRuleForm.values.contractNumber18"
                class="public-input"></el-input>
            </el-form-item>
          </div>
          <div class="right">
            <el-form-item label="2号字母设置:">
              <el-input placeholder="请输入2号字母" :maxlength="1" v-model="contractNoRuleForm.values.contractNumber2"
                class="public-input"></el-input>
            </el-form-item>
            <el-form-item label="4号字母设置:">
              <el-input placeholder="请输入4号字母" :maxlength="1" v-model="contractNoRuleForm.values.contractNumber4"
                class="public-input"></el-input>
            </el-form-item>
            <el-form-item label="17号字母设置:">
              <el-input placeholder="请输入17号字母" :maxlength="1" v-model="contractNoRuleForm.values.contractNumber17"
                class="public-input"></el-input>
            </el-form-item>
          </div>
        </div>
        <el-divider></el-divider>
        <p style="color: #f56c6c">
          说明：合同编号的1-4位字母由市场自行设置,若没有设置则默认为MYSH;5位开始为合同生成商铺类型首字母简写，简写后为合同生成时间；合同生成时间后为当前生成合同数量编号（如当日第一份合同，则为：000000）;后3位为市场自行设置，若没设置则为空
        </p>
      </el-form>
      <template slot="footer">
        <MyButton @click="contractNoVisible = false" right>取消</MyButton>
        <MyButton @click="contractNoConfirm('rule')" type="primary">确定</MyButton>
      </template>
    </el-dialog>
    <el-dialog :visible.sync="contractSealVisible" title="电子合同章上传" width="880px" @close="contractNoClose"
      :close-on-click-modal="false">
      <el-form :model="contractNoRuleForm" :rules="contractNoRules" ref="contractSealRuleForm" label-width="120px"
        class="demo-ruleForm">
        <el-form-item label="上传合同章:" prop="values.electronicSeal">
          <el-upload class="upload-demo" ref="upload" action="" :show-file-list="false" :on-change="handlePreview"
            :auto-upload="false">
            <img :src="contractNoRuleForm.values.electronicSeal" class="imgBox"
              v-if="contractNoRuleForm.values.electronicSeal" alt="" />
            <el-button icon="el-icon-upload2" type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <MyButton @click="contractSealVisible = false" right>取消</MyButton>
        <MyButton @click="contractNoConfirm('file')" type="primary">确定</MyButton>
      </template>
    </el-dialog>
    <el-dialog :visible.sync="feesdialogVisible" title="新建收费项" width="880px" @close="close" :close-on-click-modal="false">
      <el-form label-width="200px" :model="ruleForms" :rules="feesrules" ref="ruleForms">
        <el-form-item label="收费项名称:" prop="contract_fee_name">
          <el-input class="public-input" v-model="ruleForms.contract_fee_name" placeholder="输入收费项名称"></el-input>
        </el-form-item>
        <el-form-item label="计费方式:" prop="type">
          <el-select class="public-input" v-model="ruleForms.type" @change="changeType" placeholder="请选择计费方式">
            <el-option label="按月收费" :value="10"></el-option>
            <el-option label="一次性收费" :value="20"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否随基础费用缴纳:" prop="rent_type">
          <el-radio-group :disabled="true" v-model="ruleForms.rent_type" @change="changeRentType">
            <el-radio :label="10">是</el-radio>
            <el-radio :label="20">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否为多次收费:" prop="repeated_type">
          <el-radio-group :disabled="true" v-model="ruleForms.repeated_type">
            <el-radio :label="10">是</el-radio>
            <el-radio :label="20">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否必填:" prop="required_type">
          <el-radio-group v-model="ruleForms.required_type">
            <el-radio :label="10">是</el-radio>
            <el-radio :label="20">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <MyButton right @click="feesdialogVisible = false">取消</MyButton>
        <MyButton @click="feesaddconfirm" type="primary">确认</MyButton>
      </template>
    </el-dialog>
    <el-dialog title="合同预览" :visible.sync="previsible" :close-on-click-modal="false">
      <div class="prebox" v-html="preContent"></div>
      <template #footer>
        <myButton @click="previsible = false">关闭</myButton>
      </template>
    </el-dialog>
  </div>
</template>
<script>
// import { quillEditor } from 'vue-quill-editor'; //调用编辑器
// import 'quill/dist/quill.core.css';
// import 'quill/dist/quill.snow.css';
// import 'quill/dist/quill.bubble.css';
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import "@wangeditor/editor/dist/css/style.css";

export default {
  name: "contractsSetting",
  components: {
    // quillEditor
    Editor,
    Toolbar,
  },
  data () {
    return {
      loading: false,
      title: "新增合同模板",
      feesdialogVisible: false,
      formInline: {
        pageNum: 1,
        pageSize: 10,
        key: "propertyTplSetting",
      },
      total: 0,
      addvisible: false,
      tableData: [],
      contractFeeSettingList: [],
      ruleForm: {
        key: "propertyTplSetting",
        describe: "新增合同模板",
        values: {
          name: "",
          remark: "",
          marketName: "",
          address: "",
          mobile: "",
          email: "",
          chargingItemsId: [],
          chargingItemsName: [],

          chargingItems: [],
          content: "",
        },
      },
      defaultchargingItems: [
        {
          key: "totalNumberShops",
          name: "商铺总数据",
          content: "<商铺总数据>",
          status: false,
          type: 1,
        },
        {
          key: 'idCard',
          name: '商户身份证',
          content: "<商户身份证>",
          status: false,
          type: 1
        },
        {
          key: 'merchantName',
          name: '商户姓名',
          content: "<商户姓名>",
          status: false,
          type: 1
        },
        {
          key: 'shopNumber',
          name: '商铺号',
          content: "<商铺号>",
          status: false,
          type: 1
        },
        {
          key: 'rentFreePeriod',
          name: '免租期',
          content: "<免租期>",
          status: false,
          type: 1
        },
        {
          key: 'downPayment',
          name: '首期金额',
          content: "<首期金额>",
          status: false,
          type: 1
        },
        {
          key: 'rentPaymentMethod',
          name: '租金缴纳方式',
          content: "<租金缴纳方式>",
          status: false,
          type: 1
        },
        {
          key: 'grossRent',
          name: '总租金',
          content: "<总租金>",
          status: false,
          type: 1
        },
        {
          key: "charge",
          name: "收费数据",
          content: "<收费数据>",
          status: false,
          type: 2,
        },
        {
          key: "periodCcontract",
          name: "合同时间",
          content: "<合同时间>",
          status: false,
          type: 1,
        },
        {
          key: "earnestMoney",
          name: "意向金",
          content: "<意向金>",
          status: false,
          type: 2,
        },
        {
          key: "rentHoliday",
          name: "免租金额",
          content: "<免租金额>",
          status: false,
          type: 2,
        },
        {
          key: "liquidatedDamages",
          name: "违约金",
          content: "<违约金>",
          status: false,
          type: 2,
        },
        {
          key: "appoint",
          name: "缴费约定",
          content: "<缴费约定>",
          status: false,
          type: 2,
        },
        {
          key: "progressiveIncreaseRate",
          name: "递增率",
          content: "<递增率>",
          status: false,
          type: 2,
        },
        {
          key: "rememberRentTime",
          name: "计租开始时间",
          content: "<计租开始时间>",
          status: false,
          type: 1,
        },
        {
          key: "totalAmount",
          name: "月总金额",
          content: "<月总金额>",
          status: false,
          type: 2,
        },
        {
          key: "merchantsSign",
          name: "商户签字",
          content: "<商户签字>",
          status: false,
          type: 1,
        },
        {
          key: "specialAgreement",
          name: "特别约定",
          content: "<特别约定>",
          status: false,
          type: 2,
        },
        {
          key: "businessScope",
          name: "经营范围",
          content: "<经营范围>",
          status: false,
          type: 1,
        },
        {
          key: "nameMarket",
          name: "市场名称",
          content: "<市场名称>",
          status: false,
          type: 1,
        },
        {
          key: "address",
          name: "市场联系地址",
          content: "<市场联系地址>",
          status: false,
          type: 3,
        },
        {
          key: "mobile",
          name: "市场联系人电话",
          content: "<市场联系人电话>",
          status: false,
          type: 1,
        },
        {
          key: "email",
          name: "市场联系邮箱",
          content: "<市场联系邮箱>",
          status: false,
          type: 3,
        },
        {
          key: "merchantPhone",
          name: "商户联系人电话",
          content: "<商户联系人电话>",
          status: false,
          type: 3,
        },
        {
          key: "merchantAddress",
          name: "商户联系地址",
          content: "<商户联系地址>",
          status: false,
          type: 3,
        },
        {
          key: "merchantEmail",
          name: "商户联系邮箱",
          content: "<商户联系邮箱>",
          status: false,
          type: 3,
        },
        {
          key: "signingTime",
          name: "签订时间",
          content: "<签订时间>",
          status: false,
          type: 1,
        },
        {
          key: "htgz",
          name: "合同盖章",
          content: "<合同盖章>",
          status: false,
          type: 1,
        },
      ],
      rules: {
        "values.name": [
          { required: true, message: "请输入合同模板名称", trigger: "blur" },
        ],
        "values.marketName": [
          { required: true, message: "请输入甲方市场名称", trigger: "blur" },
        ],
        "values.address": [
          { required: true, message: "请输入联系地址", trigger: "blur" },
        ],
        "values.mobile": [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
        ],
        "values.email": [
          { required: true, message: "请输入联系邮箱", trigger: "blur" },
        ],
        "values.chargingItemsId": [
          { required: true, message: "请选择收费项", trigger: "change" },
        ],
      },
      dialogVisible: false,
      editorOption: {},
      contractNoVisible: false,
      contractNoRuleForm: {
        values: {
          contractNumber3: "",
          contractNumber4: "",
          contractNumber1: "",
          contractNumber2: "",
          contractNumber16: "",
          contractNumber17: "",
          contractNumber18: "",
          electronicSeal: "",
        },
        describe: "生成合同编号",
        key: "",
      },
      contractNoRules: {
        "values.electronicSeal": [
          { required: true, message: "请上传合同电子章", trigger: "change" },
        ],
      },
      contractSealVisible: false,
      toolbarConfig: {},
      editorConfig: { placeholder: "请输入内容..." },
      mode: "default", // or 'simple'
      editor: null,
      ruleForms: {
        contract_fee_name: "",
        type: "",
        is_contract_vice: 20, //是否副合同 默认10是  20不是
        rent_type: "",
        repeated_type: "",
        required_type: "",
      },
      feesrules: {
        contract_fee_name: [
          { required: true, message: "请输入收费项名称", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择计费方式", trigger: "change" },
        ],
        rent_type: [
          {
            required: true,
            message: "请选择是否属于基础费用",
            trigger: "change",
          },
        ],
        repeated_type: [
          {
            required: true,
            message: "请选择是否为多次收费",
            trigger: "change",
          },
        ],
        required_type: [
          { required: true, message: "请选择是否必填", trigger: "change" },
        ],
      },
      previsible: false,
      preContent: "",
    };
  },
  created () {
    //do something after creating vue instance
    this.getContractFeeSetting();
    this.getList();
    let ruleForm = window.sessionStorage.getItem("ruleForm") || null;
    if (ruleForm) {
      this.ruleForm = JSON.parse(ruleForm);
    }
  },
  watch: {
    ruleForm: {
      handler (value) {
        if (this.title == "新增合同模板") {
          window.sessionStorage.setItem(
            "ruleForm",
            JSON.stringify(this.ruleForm)
          );
        }
      },
      deep: true,
    },
  },
  methods: {
    onCreated (editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    getRule (row) {
      if (!row.child_value.values) {
        return "";
      }
      return `${row.child_value.values.contractNumber1}${row.child_value.values.contractNumber2}${row.child_value.values.contractNumber3}${row.child_value.values.contractNumber4}-XXXXXXXXXXX${row.child_value.values.contractNumber16}${row.child_value.values.contractNumber17}${row.child_value.values.contractNumber18}`;
    },
    ruleNo (row, type) {
      this.contractNoRuleForm.key = `contractNumberSetting${row.property_setting_id}`;
      this.$request
        .HttpGet("/setting/edit", {
          key: `contractNumberSetting${row.property_setting_id}`,
        })
        .then((res) => {
          if (res.data) {
            this.contractNoRuleForm = res.data;
          }
          if (type == "rule") {
            this.contractNoVisible = true;
          } else {
            this.contractSealVisible = true;
          }
        });
    },
    async contractNoConfirm (type) {
      let isValidate = true;
      if (type == "file") {
        await this.$refs.contractSealRuleForm.validateField(
          "values.electronicSeal",
          (electronicSeal) => {
            if (electronicSeal) {
              isValidate = false;
            }
          }
        );
      }
      if (isValidate) {
        this.$request
          .HttpPost("/setting/add", this.contractNoRuleForm)
          .then((res) => {
            this.$common.notifySuccess(res.msg);
            if (type == "rule") {
              this.contractNoVisible = false;
            } else {
              this.contractSealVisible = false;
            }
          });
      }
      this.getList();
    },
    handlePreview (file) {
      if (file && file.raw) {
        let formData = new FormData();
        formData.append("file", file.raw);
        this.$request.HttpPost("/system/upload/upImg", formData).then((res) => {
          this.$refs.contractSealRuleForm.clearValidate(
            "values.electronicSeal"
          );
          this.$set(
            this.contractNoRuleForm.values,
            "electronicSeal",
            process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl
          );
        });
      }
    },
    contractNoClose () {
      this.contractNoRuleForm = {
        values: {
          contractNumber3: "",
          contractNumber4: "",
          contractNumber1: "",
          contractNumber2: "",
          contractNumber16: "",
          contractNumber17: "",
          contractNumber18: "",
          electronicSeal: "",
        },
        describe: "生成合同编号",
        key: "",
      };
    },
    onEditorChange (event) {
      this.ruleForm.values.chargingItems.forEach((item) => {
        if (event.getText().indexOf(item.content) == -1) {
          item.status = false;
        } else {
          item.status = true;
        }
      });
    },
    onEditorFocus () { },
    onEditorBlur () { },
    insertion (item) {
      this.$refs.myQuillEditor.editor.insertText(item.content);
      item.status = true;
      // let cursor = this.$refs.myQuillEditor.quill.selection.savedRange.index;
      // console.log(cursor);
      // if (cursor) {
      //   console.log('fsdfsd');
      //   this.$nextTick(()=>{
      //     this.$refs.myQuillEditor.quill.insertText(cursor, item.content)
      //   item.status = true;
      //   })
      // }
    },
    nextStep () {
      this.$refs.ruleForm.validate((vaild) => {
        if (vaild) {
          this.ruleForm.values.chargingItems = [];
          this.ruleForm.values.chargingItemsName = [];
          let arr = [];
          for (let i in this.contractFeeSettingList) {
            if (
              this.ruleForm.values.chargingItemsId.indexOf(
                this.contractFeeSettingList[i].id
              ) != -1
            ) {
              arr.push({
                key: this.contractFeeSettingList[i].id,
                name: this.contractFeeSettingList[i].contract_fee_name,
                content: `<${this.contractFeeSettingList[i].contract_fee_name}>`,
                status: false,
                type: 2,
              });
              this.ruleForm.values.chargingItemsName.push(
                this.contractFeeSettingList[i].contract_fee_name
              );
            }
          }
          this.ruleForm.values.chargingItems = [
            ...this.defaultchargingItems,
            ...arr,
          ];
          this.dialogVisible = true;
        }
      });
    },
    add () {
      let ruleForm = window.sessionStorage.getItem("ruleForm") || null;
      if (ruleForm) {
        this.ruleForm = JSON.parse(ruleForm);
      } else {
        this.ruleForm = {
          key: "propertyTplSetting",
          describe: "新增合同模板",
          values: {
            name: "",
            remark: "",
            marketName: "",
            address: "",
            mobile: "",
            email: "",
            chargingItemsId: [],
            chargingItemsName: [],
            chargingItems: [
              {
                key: "totalNumberShops",
                name: "商铺总数据",
                content: "<商铺总数据>",
                status: false,
                type: 1,
              },
              {
                key: "charge",
                name: "收费数据",
                content: "<收费数据>",
                status: false,
                type: 2,
              },
              {
                key: "periodCcontract",
                name: "合同时间",
                content: "<合同时间>",
                status: false,
                type: 1,
              },
              {
                key: "earnestMoney",
                name: "意向金",
                content: "<意向金>",
                status: false,
                type: 2,
              },
              {
                key: "rentHoliday",
                name: "免租金额",
                content: "<免租金额>",
                status: false,
                type: 2,
              },
              {
                key: "liquidatedDamages",
                name: "违约金",
                content: "<违约金>",
                status: false,
                type: 2,
              },
              {
                key: "appoint",
                name: "缴费约定",
                content: "<缴费约定>",
                status: false,
                type: 2,
              },
              {
                key: "progressiveIncreaseRate",
                name: "递增率",
                content: "<递增率>",
                status: false,
                type: 2,
              },
              {
                key: "rememberRentTime",
                name: "计租开始时间",
                content: "<计租开始时间>",
                status: false,
                type: 1,
              },
              {
                key: "totalAmount",
                name: "月总金额",
                content: "<月总金额>",
                status: false,
                type: 2,
              },
              {
                key: "merchantsSign",
                name: "商户签字",
                content: "<商户签字>",
                status: false,
                type: 1,
              },
              {
                key: "specialAgreement",
                name: "特别约定",
                content: "<特别约定>",
                status: false,
                type: 2,
              },
              {
                key: "businessScope",
                name: "经营范围",
                content: "<经营范围>",
                status: false,
                type: 1,
              },
              {
                key: "nameMarket",
                name: "市场名称",
                content: "<市场名称>",
                status: false,
                type: 1,
              },
              {
                key: "address",
                name: "市场联系地址",
                content: "<市场联系地址>",
                status: false,
                type: 3,
              },
              {
                key: "mobile",
                name: "市场联系人电话",
                content: "<市场联系人电话>",
                status: false,
                type: 1,
              },
              {
                key: "email",
                name: "市场联系邮箱",
                content: "<市场联系邮箱>",
                status: false,
                type: 3,
              },
              {
                key: "merchantPhone",
                name: "商户联系人电话",
                content: "<商户联系人电话>",
                status: false,
                type: 3,
              },
              {
                key: "merchantAddress",
                name: "商户联系地址",
                content: "<商户联系地址>",
                status: false,
                type: 3,
              },
              {
                key: "merchantEmail",
                name: "商户联系邮箱",
                content: "<商户联系邮箱>",
                status: false,
                type: 3,
              },
              {
                key: "signingTime",
                name: "签订时间",
                content: "<签订时间>",
                status: false,
                type: 1,
              },
              {
                key: "htgz",
                name: "合同盖章",
                content: "<合同盖章>",
                status: false,
                type: 1,
              },
            ],
            content: "",
          },
        };
      }
      this.title = "新增合同模板";
      this.addvisible = true;
    },
    edit (row) {
      this.title = "编辑合同模板";
      let str = JSON.parse(JSON.stringify(row));
      this.ruleForm = { ...str };
      this.ruleForm.values.chargingItems = this.defaultchargingItems;
      this.ruleForm.values.chargingItemsName = [];
      this.addvisible = true;
    },
    getContractFeeSetting () {
      this.$request
        .HttpPost("/contract_fee_setting/listAll", {
          status: 1,
          is_contract_vice: 20, //是否副合同 默认10是  20不是
        })
        .then((res) => {
          this.contractFeeSettingList = res.data || [];
        });
    },
    currentChange (page) {
      this.formInline.pageNum = page;
      this.getList();
    },
    getList () {
      this.loading = true;
      this.$request
        .HttpPost("/setting/list", this.formInline)
        .then((res) => {
          if (res.data.list) {
            res.data.list.forEach((item) => {
              item.values = JSON.parse(item.values);
              if (item.child_value) {
                item.child_value.values = item.child_value
                  ? JSON.parse(item.child_value.values)
                  : {};
              } else {
                item.child_value = {};
              }
            });
            this.tableData = res.data.list || [];
          }
          this.total = res.data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    confirm () {
      let url = "/setting/add";
      if (this.ruleForm.property_setting_id) {
        url = "/setting/edit";
      }
      this.$request.HttpPost(url, this.ruleForm).then((res) => {
        this.$common.notifySuccess(res.msg);
        this.getList();
        this.addvisible = false;
        this.dialogVisible = false;
      });
    },
    contentClose () {
      this.ruleForm.content = "";
      this.title = "新增合同模板";
      const editor = this.editor;
      if (editor == null) return;
      editor.destroy();
    },
    close () { },
    changeType (e) {
      if (e == 20) {
        this.isRentType = true;
        this.ruleForms.rent_type = 20;
        this.ruleForms.refund_type = 10;
        this.ruleForms.repeated_type = 20;
      } else {
        this.isRentType = false;
        this.ruleForms.rent_type = 10;
        this.ruleForms.refund_type = 20;
        this.ruleForms.repeated_type = 10;
      }
    },
    // 却换是否属于基础费用
    changeRentType (e) {
      if (e == 20) {
        this.ruleForms.repeated_type = 20;
        this.ruleForms.refund_type = 10;
      } else {
        this.ruleForms.refund_type = 20;
        this.ruleForms.repeated_type = 10;
      }
    },
    feesaddconfirm () {
      this.$refs["ruleForms"].validate((valid) => {
        if (valid) {
          this.$request
            .HttpPost("/contract_fee_setting/add", this.ruleForms)
            .then((res) => {
              this.$common.notifySuccess(res.msg);
              this.feesdialogVisible = false;
              this.getContractFeeSetting();
            });
        }
      });
    },
    getPre (row) {
      console.log(row);
      this.preContent = row.values.content;
      // this.$request.HttpGet("/contract/edit",{id:row.contract_id})
      // .then(res =>{
      //   console.log(res);
      // })
      this.previsible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.marginBt {
  margin-bottom: 10px;
}

.listBox {
  .listHead {
    display: flex;
    align-items: center;

    .list {
      width: 15px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      border: 1px solid #333333;
      box-sizing: border-box;
      margin-right: 5px;
    }

    .segmentation {
      margin-right: 5px;
    }
  }

  .listName {
    display: flex;

    .list {
      width: 15px;
      text-align: center;
      box-sizing: border-box;
      margin-right: 5px;
      line-height: normal;
    }

    .segmentation {
      margin-right: 5px;
      line-height: normal;
    }
  }
}

.contractNoBox {
  display: flex;

  .left,
  .right {
    flex: 1;
  }
}

.imgBox {
  width: 200px;
  display: block;
  margin-bottom: 10px;
}

.quill-editor {
  /deep/ .ql-container {
    max-height: 500px;
    overflow-y: auto;
  }
}
</style>
